import { variantProvider, AppImages, I18N } from '@/app'
import { AppStatus, useAppSelector } from '@/redux'
import { Image, View, Text, Button, Modal } from '@/components'
import { navigate } from 'gatsby'
import { useIsMobile } from '@/utils'

export const CongratulationsStories = (modalProps) => {
  const isOpen = useAppSelector(store => store.AppStatus.modals.congratulationsStories)
  const storyId = useAppSelector(s => s.Session.storyId)

  const isMobile = useIsMobile()

  const handleSeeSuccessStories = () => {
    AppStatus.setModal('congratulationsStories')
    navigate(`/community/?storyId=${storyId}`)
  }

  return (
    <Modal
      showClose={false}
      variants={['centered']}
      visible={isOpen}
      onClose={() => AppStatus.setModal('congratulationsStories')}
      styles={{
        box: styles.box,
        body: styles.body,
      }}
      {...modalProps}
    >
      {isMobile ? (
        <Text
          variants={['h2', 'textCenter', 'color:neutral9']}
          responsiveVariants={{ small: ['h4'] }}
          text={I18N.t('modals.successStory.title')}
        />
      ) : null}

      <Image style={styles.image} source={AppImages.Success} />

      <View variants={['column', 'center', 'gap:0.5']}>
        {isMobile ? null : <Text variants={['h2', 'textCenter', 'color:neutral9']} text={I18N.t('modals.successStory.title')} />}
        <Text variants={['p1', 'textCenter', 'color:neutral6']} text={I18N.t('modals.successStory.description')} />
      </View>

      <Button
        variants={['fullWidth']}
        text={I18N.t('modals.successStory.confirmButton')}
        debugName={'Story Success Modal'}
        onPress={handleSeeSuccessStories}
      />
    </Modal>
  )
}

const WIDTH = 400
const IMAGE_WIDTH = 150

const styles = variantProvider.createComponentStyle((theme) => ({
  image: {
    width: IMAGE_WIDTH,
    height: 'auto',

    [theme.media.down('small')]: {
      width: IMAGE_WIDTH * 0.7,
    },
  },
  box: {
    width: `calc(100vw - ${theme.spacing.value(4)}px)`,
    maxWidth: WIDTH,
    ...theme.spacing.padding(4),

    [theme.media.down('small')]: {
      ...theme.spacing.padding(2),
    },
  },
  body: {
    ...theme.presets.column,
    ...theme.presets.alignCenter,
    ...theme.spacing.gap(4),

    [theme.media.down('small')]: {
      ...theme.spacing.gap(2),
    },
  },
}), true)
