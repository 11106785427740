import { TypeGuards } from '@codeleap/common'

type Subscriber<T> = (e: T) => any

type SubscriptionRecord = Record<string, Topic<any>>

type MultiSubscriptor<T extends SubscriptionRecord> = <K extends keyof T>(
  e: K,
  cb: Subscriber<T[K] extends Topic<infer ET> ? ET : never>,
) => () => void

class Topic<T> {
  static registry: Record<string, Topic<any>> = {}

  static named<T>(name: string) {
    if (!Topic.registry[name]) {
      Topic.registry[name] = new Topic<T>()
    }
    return Topic.registry[name] as Topic<T>
  }

  destroy() {
    delete Topic.registry[this.constructor.name]
  }

  private subscribers: Set<Subscriber<T>> = new Set()

  private _history: T[] = []

  get history() {
    return Object.freeze(this._history)
  }

  subscribe(cb: Subscriber<T>) {
    const newLen = this.subscribers.add(cb)
    return () => {
      this.subscribers.delete(cb)
    }
  }

  wait(timeout: number | null = 10000) {
    return new Promise<T>((resolve, reject) => {
      const unsub = this.subscribe((e) => {
        resolve(e)
        unsub()
      })

      if (!TypeGuards.isNil(timeout)) {
        setTimeout(() => {
          reject(new Error('Timeout after ' + timeout + ' ms'))
          unsub()
        }, timeout)
      }
    })
  }

  publish(e: T) {
    this._history.push(e)

    this.subscribers.forEach((s) => {
      s(e)
    })
  }

  removeAllListeners() {
    this.subscribers.clear()
  }

  static createSubscriptor<SR extends SubscriptionRecord>(
    events: SR,
  ): MultiSubscriptor<SR> {
    return (e, cb) => {
      return events[e]!.subscribe(cb)
    }
  }
}

export { Topic }
