import { AppStatus, Session, useAppSelector } from '@/redux'
import { analytics, AnalyticsEvents, APIClient } from '@/services'
import { authWall } from '../authWall'

export async function handleOnboardingValidations(type: 'stories' | 'review') {
  const isLoggedIn = APIClient.Session.isLoggedIn()
  const profile = APIClient.Session.getProfile()

  if (!isLoggedIn) {
    authWall(() => {}, true)()
    return false
  }

  const signupComplete = await APIClient.Session.checkSignupCompletion(profile)
  if (!signupComplete.isComplete) {
    if (signupComplete.nextStep === 'VerifyEmail') {
      return true
    }
    // @ts-ignore
    AppStatus.setModal(signupComplete.modal ?? signupComplete.nextStep)
    Session.setAbortedAction(type)
    return false
  }

  return true
}

export function useAct() {
  const pulse = APIClient.PulseQuestions.usePulseQuestions()

  async function onPressOrganisation(bypass = false) {
    if (bypass || await handleOnboardingValidations('review')) {
      AnalyticsEvents.pressContribute()
      if (!pulse.available) {
        AppStatus.setModal('noReviewQuestions')
        return
      }
      pulse.openQuestions('act_page')
    }
  }

  async function onPressStories(bypass = false) {
    if (bypass || await handleOnboardingValidations('stories')) {
      AnalyticsEvents.pressContribute()
      analytics.track('story_add_start', { source_from: 'act' })
      AppStatus.setModal('createStory')
    }
  }

  const abortedAction = useAppSelector(s => s.Session.abortedAction)
  const hasAbortedAction = ['stories', 'review'].includes(abortedAction)

  const handleAbortedAction = async () => {
    if (!hasAbortedAction) return
    if (abortedAction === 'stories') {
      onPressStories(true)
    } else {
      onPressOrganisation(true)
    }
  }

  return {
    onPressOrganisation,
    onPressStories,
    handleAbortedAction,
    hasAbortedAction,
  }
}
