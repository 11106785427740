import { AppStatus, Session, useAppSelector } from '@/redux'
import { getFirebase } from '../../firebaseApp'
import { deepEqual, onUpdate, ReactQuery, TypeGuards, useEffect, useIsomorphicEffect, usePromise, useRef, useState } from '@codeleap/common'
import { create, retrieve, update, retrieveOptions, retrieveProfanityWords, passwordReset } from './views'
import { QueryKeys } from './queryKeys'
import { toggleOnboardingQuestionsVisibility, LocalStorage, useFirebase, useFirebaseUser, useToggleDevMode } from '@/utils'
import { navigate } from 'gatsby'
import { queryClient } from '../queryClient'
import { IS_SSR, IsDevEnv, LocalStorageKeys, I18N, INTERACTIVE_SPLASH_TRANSITION, React } from '@/app'
import { Profile } from '@/types'
import { APIClient, analytics } from '@/services'
import { useScoreRanks } from '../answers'
import { useProfile } from './useProfile'

const userRef = React.createRef()

const warnEmailInUse = () => {
  alert(I18N.t('emailInUseMsg'))
}

const verify = (v) => !TypeGuards.isNil(v)

const DEBUG = IsDevEnv && false

const userHasSocialLogin = async () => {
  if (IsDevEnv && DEBUG) return { hasSocialLogin: true }

  const firebase = await getFirebase()

  const firebaseUser = firebase.auth().currentUser

  const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(firebaseUser?.email)

  const idTokenResult = await firebaseUser.getIdTokenResult()

  if (idTokenResult?.claims?.linkedin_id) {
    return { hasSocialLogin: true, signInMethod: 'linkedIn' }
  }

  const hasSocialLogin = signInMethods?.some(x => x !== 'password')

  return { hasSocialLogin, signInMethod: signInMethods[0] }
}

const isEmailVerified = async (data) => {
  const firebase = await getFirebase()
  const firebaseUser = firebase.auth().currentUser
  const { hasSocialLogin } = await userHasSocialLogin()

  if (hasSocialLogin) return true
  if (!firebaseUser || !data) return false
  if (data?.id !== firebaseUser.uid) return false
  return firebaseUser.emailVerified
}

import { Topic } from '../../../utils/hooks/topic' //WTF!
export const emailVerification = Topic.named<boolean>('emailVerification')

export const ensureEmailVerified = async (profile) => {
  const emailVerified = await isEmailVerified(profile)

  if (!emailVerified) {
    AppStatus.setModal('verifyEmailBefore')
    const res = await emailVerification.wait(null)

    if (!res) {
      throw new Error('Email not verified')
    }
  }
}

export async function checkSignupCompletion(data) {
  const firebase = await getFirebase()

  const signupInfo = [
    data?.first_name,
    data?.last_name,
    data?.email,
  ]

  const hasCompletedSignup = signupInfo.every(verify)

  const personalInfo = [
    data?.inbound_source,
    (data?.inbound_source === 'other' && !!data?.inbound_source_details) || data?.inbound_source,
    data?.climate_priority,
  ]

  const hasCompletedPersonalInfo = personalInfo.every(verify)

  const orgInfo = [
    data?.seniority,
    data?.organization?.id,
    data?.department,
  ]

  const hasCompletedOrganizationInfo = orgInfo.every(verify)

  logger.debug('Signup completion', { signupInfo, personalInfo, orgInfo }, 'Auth')

  const steps = [
    ['signup', hasCompletedSignup],
    ['aboutYou', hasCompletedPersonalInfo],
    ['aboutJob', hasCompletedOrganizationInfo],
  ] as const

  const nextStep = steps.find(x => !x?.[1])?.[0]

  const isComplete = steps.every(x => x?.[1])

  if (isComplete && !(await isEmailVerified(data))) {
    return {
      isComplete: false,
      nextStep: 'VerifyEmail',
      modal: 'verifyEmailBefore',
    }
  }

  return {
    isComplete,
    nextStep,
  }
}

const SPLASH_TRANSITION = 1500

export const useProfileOptions = () => {

  const options = ReactQuery.useQuery({
    queryKey: QueryKeys.options.key,
    queryFn: retrieveOptions,
    refetchOnMount(q) {
      return q.state.dataUpdateCount == 0
    },
  })

  return options
}

export const useProfanityWords = () => {
  const options = ReactQuery.useQuery({
    queryKey: QueryKeys.profanityWords.key,
    queryFn: retrieveProfanityWords,
    refetchOnMount(q) {
      return q.state.dataUpdateCount == 0
    },
  })
  return options
}

export const toggleRememberMe = async (rememberMe: boolean) => {
  const firebase = await getFirebase()
  const persistance = rememberMe ? firebase.auth.Auth.Persistence.SESSION : firebase.auth.Auth.Persistence.NONE
  firebase.auth().setPersistence(persistance)
}

type AuthState = 'logged_in' | 'timeout' | 'pending'
type AuthStatePayload = {
  profile: Profile
  firebaseUser: any
  state: AuthState
}

const useProfileQuery = (enabled = false) => {
  return ReactQuery.useQuery({
    queryKey: QueryKeys.me.key,
    queryFn: async () => {
      const response = await retrieve()
      if (response && !deepEqual(userRef.current, response)) {
        analytics.identify({ user: response, isLoggedIn: true })
        analytics?.track('logged_in_pageview')
        userRef.current = response
      }

      return response
    },
    refetchOnMount(q) {
      return q.state.dataUpdateCount == 0
    },
    retry: false,
    enabled,
    onError() {
      QueryKeys.me.setData(null)
    },
  })
}

export const useAuthState = () => {
  const { firebaseUser } = useFirebaseUser()
  const djangoUser = useProfileQuery(!!firebaseUser)?.data

  const [state, setState] = useState<AuthState>('pending')

  const authPromise = usePromise<AuthStatePayload>({
    debugName: 'AuthState',
    onResolve(payload) {
      setState(payload.state)
    },
    onReject() {
      setState('timeout')
    },
  })

  const isAuthenticated = !!firebaseUser?.uid && !!djangoUser?.id

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (isAuthenticated) {
      authPromise.resolve({
        firebaseUser,
        profile: djangoUser,
        state: 'logged_in',
      })
      return
    } else {
      timeoutId = setTimeout(() => {
        authPromise.resolve({
          firebaseUser,
          profile: djangoUser,
          state: 'timeout',
        })
      }, 5000)
    }

    return () => {
      clearTimeout(timeoutId)
    }

  }, [djangoUser?.id, firebaseUser?.uid])

  return {
    isAuthenticated,
    firebaseUser,
    djangoUser,
    awaitAuth: authPromise._await,
    state: state,
  }

}

export function useSession(root = false) {
  const devMode = useToggleDevMode(root)

  const authFinished = useAppSelector(store => store.AppStatus.authFinished)
  const initialAuthResolved = useAppSelector(store => store.AppStatus.hasResolvedInitialAuth)

  const [hasFirebaseUser, setHasFirebaseUser] = useState(null)

  const profile = useProfile()

  const organisation = APIClient.Organisations.organisationsManager.useItem({
    id: profile?.data?.organization?.id,
  })

  const score = organisation?.data?.score
  const ranks = useScoreRanks()
  const organisationRank = ranks?.data?.find((rank) => score?.overall >= rank?.score)

  const data = { ...profile.data, organisationRank, score }

  useEffect(() => {
    if (!!profile.data && root) {
      analytics.identify({ user: data, isLoggedIn: true })
    }
  }, [profile.dataUpdatedAt])

  const options = useProfileOptions()
  const profanityWords = useProfanityWords()

  const createProfile = ReactQuery.useMutation({
    mutationKey: QueryKeys.create.key,
    mutationFn: create,
    onSuccess: () => {
      QueryKeys.me.refresh()
    },
  })

  const updateProfile = ReactQuery.useMutation({
    mutationKey: QueryKeys.create.key,
    mutationFn: update,
    onSuccess: () => {
      QueryKeys.me.refresh()
    },
  })

  const logout = (withDone = true, isDelete = false) => {
    return new Promise<void>(async (resolve) => {
      if (withDone) {
        AppStatus.set('loading')
      }
      if (!IS_SSR) {

        localStorage.clear()
      }

      const firebase = await getFirebase()

      if (withDone) {
        AppStatus.set('loading')
      }

      setTimeout(() => {
        firebase.auth().signOut()
        queryClient.client.clear()
        AppStatus.clearModals()
        if (!isDelete) {
          analytics.track('log_out')
        }

        analytics.identify({ user: profile.data, isLoggedIn: false })
        setTimeout(() => {

          if (withDone) {
            AppStatus.set('done')
          }

          setTimeout(() => {
            analytics.reset()
            navigate('/')
            resolve()
          })
        }, 1000)
      }, 2000)

      Session.setHasLoggedOut(true)
      toggleOnboardingQuestionsVisibility()
    })

  }

  const loginResolved = typeof profile.data !== 'undefined'

  const onOnboardingFinished = () => {
    AppStatus.authFinished()
  }

  const onInitialAuthResolved = () => {
    // AppStatus.set('interactive')

    setTimeout(() => {
      AppStatus.initialAuthResolved()
    }, INTERACTIVE_SPLASH_TRANSITION)
  }

  const requestPasswordReset = async (email: string) => {

    await AppStatus.set('loading')

    try {
      await passwordReset(email)

      setTimeout(() => {
        AppStatus.set('done')
      }, 2000)

    } catch (e) {
      await AppStatus.set('idle')
      console.error('Password reset', e, 'Auth')
      alert('There was an error during password reset')
    }
  }

  const reauthenticate = async (password: string) => {
    const firebase = await getFirebase()

    return firebase.auth().signInWithEmailAndPassword(profile.data.email, password)
  }

  const resolvedAuth = useRef(false)

  useIsomorphicEffect(() => {
    if (!root) return

    let unsubscribe: () => void

    const init = async () => {
      const firebase = await getFirebase()

      unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {

        if (user?.uid) {
          localStorage.setItem(LocalStorageKeys.PERSIST_AUTH, 'true')
        } else {
          localStorage.removeItem(LocalStorageKeys.PERSIST_AUTH)
        }

        if (user) {
          setHasFirebaseUser(user?.uid)

          setTimeout(() => {
            QueryKeys.me.refresh()
          }, 1500)
        } else {
          await QueryKeys.me.setData(null)
        }

        // if (!resolvedAuth.current) {
        //   setTimeout(() => {
        //     console.log('Splash transition unsubscribe')
        //     AppStatus.set('idle')
        //   }, SPLASH_TRANSITION)
        // }

        resolvedAuth.current = true
      })
    }

    init()

  }, [])

  onUpdate(() => {
    if (!profile.isFetched && root && devMode.loaded) {
      profile.refetch()
        .then(async (result) => {
          if (result.isSuccess) {
            // AppStatus.authFinished()

            APIClient.Achievements.prefetchAchievements()
          }
        })
        .catch(() => { })
    }
  }, [devMode.loaded])

  const onboardingQuestions = JSON.parse(LocalStorage.getItem(LocalStorageKeys.ONBOARDING_QUESTIONS))
  const isResolved = useRef(false)

  onUpdate(() => {
    if (!TypeGuards.isNil(profile.data) && !TypeGuards.isNil(onboardingQuestions) && !isResolved.current) {
      isResolved.current = true
      updateProfile.mutateAsync(onboardingQuestions).then(() => {
        LocalStorage.removeItem(LocalStorageKeys.ONBOARDING_QUESTIONS)
      })
    }
  }, [profile.data, onboardingQuestions])

  onUpdate(() => {
    if (loginResolved && root && !initialAuthResolved) {
      onInitialAuthResolved()
    }
  }, [loginResolved])

  const optionsLoaded = options.isFetched

  const hasUnknownOrg = profile?.data?.organization?.is_unknown

  return {
    profile: profile.data,
    options: options.data,
    profanityWords: profanityWords.data,
    reauthenticate,
    updateProfile,
    createProfile,
    logout,
    isLoggedIn: !!profile.data,
    loginResolved,
    authFinished,
    onOnboardingFinished,
    requestPasswordReset,
    warnEmailInUse,
    profileQuery: profile,
    optionsLoaded,
    hasFirebaseUser,
    hasUnknownOrg,
    userHasSocialLogin,
  }
}

export const useIsSocialLogin = () => {
  const loggedInWithLinkedin = useAppSelector(store => !!store.Session?.firebaseUserClaims?.linkedin_id)

  const firebase = useFirebase()
  const firebaseUser = firebase?.auth()?.currentUser

  const isSocialLogin = !!firebaseUser?.providerData?.some((provider) => provider?.providerId !== 'password') || loggedInWithLinkedin

  return {
    isSocialLogin,
    firebaseUser,
  }
}

export function isLoggedIn() {
  const me = QueryKeys.me.getData()

  return !!me?.id
}

export function getProfile() {
  const me = QueryKeys.me.getData()

  return me
}
