import { AuthForms } from '@/components'
import { AppImages, LocalStorageKeys, React } from '@/app'
import { AuthModal } from './AuthModal'
import { APIClient } from '@/services'
import { LocalStorage } from '@/utils'
import { useState } from 'react'

const IMAGE_HEIGHT = 156

export const VerifyEmailBeforeModal = () => {
  const [hasSkipped, setHasSkipped] = useState(!!LocalStorage.getItem(LocalStorageKeys.HAS_SKIPPED_EMAIL_VERIFICATION))

  const onClose = () => {
    if (!hasSkipped) {
      APIClient.Session.emailVerification.publish(true)
      LocalStorage.setItem(LocalStorageKeys.HAS_SKIPPED_EMAIL_VERIFICATION, true)
      setHasSkipped(true)
    }
  }

  return (
    <AuthModal
      image={AppImages.MailBox}
      name='verifyEmailBefore'
      imageHeight={IMAGE_HEIGHT}
      small
      centered={false}
      closable={!hasSkipped}
      onClose={onClose}
      center
    >
      <AuthForms.VerifyEmailBeforeForm />
    </AuthModal>
  )
}
