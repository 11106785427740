import { AppStatus, useAppSelector } from '@/redux'
import { useAct } from './useAct'
import { APIClient } from '@/services'

export function useOnboarding() {
  const { handleAbortedAction, hasAbortedAction } = useAct()

  const { isSocialLogin, firebaseUser } = APIClient.Session.useIsSocialLogin()
  const modals = useAppSelector(s => s.AppStatus.modals)

  const steps = ['aboutYou', 'aboutJob']

  if (!isSocialLogin && !!firebaseUser?.uid && !hasAbortedAction) {
    steps.push('verifyEmailBefore')
  }

  const currentIndex = steps.findIndex(step => modals[step])

  const currentStep = steps[currentIndex]
  const nextStep = steps[currentIndex + 1]
  const prevStep = steps[currentIndex - 1]

  const next = async () => {
    if (!nextStep) {
      // @ts-ignore
      AppStatus.setModal([currentStep, false])
      handleAbortedAction()
      return
    }
    // @ts-ignore
    AppStatus.transitionModals([currentStep, nextStep])
  }

  return {
    currentIndex,
    nextStep,
    prevStep,
    next,
    currentStep,
  }
}
