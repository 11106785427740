import React from 'react'
import { ActionIcon, View, Image } from '@/components'
import { AnyFunction, PropsOf } from '@codeleap/common'
import { Theme, variantProvider } from '@/app'
import { AppStatus, ModalName } from '@/redux'

export type AuthModalHeaderProps = {
  name: ModalName
  image: PropsOf<typeof Image>['source']
  imageHeight: number
  centered?: boolean
  showClose?: boolean
  onClose?: AnyFunction
}

const ModalHeaderComponent = ({ image, name, imageHeight, centered = false, showClose = true, onClose }: AuthModalHeaderProps) => {
  return (
    <View variants={['column', 'justifyCenter', 'bg:primary1', 'paddingHorizontal:4', 'paddingTop:3']}
      style={styles.modalHeader}
    >
      <View variants={['column', 'justifyCenter', 'relative', 'alignCenter', !centered && 'justifyEnd']} style={styles.innerWrapper} >
        {showClose ? (
          <ActionIcon
            debugName={`${name}:closeButton`}
            name={'x'}
            variants={['minimal', 'primary3', 'alignSelfEnd', 'absolute']}
            style={styles.modalHeaderIcon}
            iconProps={{
              size: Theme.values.iconSize[3],
            }}
            onPress={() => {
              AppStatus.setModal(name)
              onClose?.()
            }}
          />
        ) : null}
        <Image
          source={image}
          objectFit='contain'
          css={[{ height: imageHeight }]}
        />
      </View>
    </View>
  )
}

export const AuthModalHeader = React.memo(ModalHeaderComponent, () => true)

const MODAL_HEADER_HEIGHT = 250

const styles = variantProvider.createComponentStyle((theme) => ({
  innerWrapper: {
    ...theme.presets.fullHeight,
  },
  modalHeader: {
    height: MODAL_HEADER_HEIGHT,
    borderTopLeftRadius: theme.borderRadius.large,
    borderTopRightRadius: theme.borderRadius.large,
  },
  modalHeaderIcon: {
    top: '0',
  },
}), true)
