import { IS_SSR } from '@/app'
import { LocalStorage } from '@/utils'
import { ReactQuery } from '@codeleap/common'

type SharedSettings = {
  initial_lesson: number
  splash: {
    text: string
  }
  all_industries: {
    text: string
  }
}

const storagekey = 'SHARED_SETTINGS'

export async function fetchSharedSettings(): Promise<SharedSettings> {
  try {
    const response = await fetch('/shared_settings.json')
    const parsedSettings = await response.json()

    LocalStorage.setItem(storagekey, parsedSettings)

    return parsedSettings
  } catch (error) {
    logger.log('Error fetching shared settings: ', error)
  }
}

export function getSharedSettings(): SharedSettings {
  if (IS_SSR) return null
  try {
    const storedSettings = JSON.parse(LocalStorage.getItem(storagekey))

    return storedSettings
  } catch (e) {}
}

export function useSharedSettings() {
  const query = ReactQuery.useQuery({
    queryKey: ['sharedSettings'],
    queryFn: fetchSharedSettings,
    staleTime: Infinity,
  })

  return {
    isUpdated: !query?.isPlaceholderData && query?.isSuccess,
    isLoaded: !!query?.data,
    settings: query.data,
  }
}
