import React from 'react'
import { useLocation } from '@reach/router'
import { Button, Text, View } from '@/components'
import { AppStatus } from '@/redux'
import { useI18N } from '@codeleap/common'
import { navigate } from 'gatsby'
import { ReauthenticationStore } from '@/utils'
import { APIClient } from '@/services'

type VerifyEmailProps = {}

export const VerifyEmailAfterForm = (props: VerifyEmailProps) => {
  const location = useLocation()
  const { t } = useI18N()

  const onContinue = () => {
    const { isReauthentication } = ReauthenticationStore.get()

    if (isReauthentication) {
      AppStatus.setModal('verifyEmailAfter')
      ReauthenticationStore.reset()
      return null
    }

    const redirectToScreen = location?.pathname?.includes?.('signup')

    if (redirectToScreen) {
      setTimeout(() => navigate('/auth/aboutYou/'))
    }
    AppStatus.setModal(['verifyEmailAfter', false])
    APIClient.Session.emailVerification.publish(true)
  }

  return (
    <View variants={['flex', 'column']}>
      <Text
        variants={['h1', 'extraBold', 'textCenter', 'marginBottom:4']}
        text={t('VerifyEmail.title2')}
      />
      <Text
        variants={['p1', 'marginBottom:4', 'marginTop:-3', 'textCenter']}
        text={t('VerifyEmail.sub2')}
      />

      <Button
        text={t('VerifyEmail.continue')}
        onPress={onContinue}
        debugName={'Open email'}
        variants={['marginHorizontal:auto', 'fullWidth', 'large', 'pill', 'marginTop:auto']}
      />
    </View>
  )
}
