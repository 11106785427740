import { APIClient, useSharedSettings } from '@/services'
import { ScoresContext } from './context'
import { Link, Text } from '..'

export const SeeFullList = () => {
  const { industry } = ScoresContext.useIndustrySelector()
  const allIndustriesText = useSharedSettings()?.settings?.all_industries?.text

  const allIndustriesSelected = industry === 'all'
  const industryData = APIClient.Organisations.useIndustry(industry)
  const industryScores = APIClient.Organisations.useScores({ category: industry })

  if (!industryData || !industryScores && !allIndustriesSelected) return null

  let description = industryData?.industry?.description
  if (allIndustriesSelected) description = allIndustriesText

  if (!description || industryScores?.n_orgs < 4) return null

  return <Text>
    <Text text={'Organisations included in score:'} variants={['h5', 'inline']}/> {description} <Link variants={['color:primary3', 'cursor:pointer']} to={`/organisations/?industry=${industry}`} text='See full list' />
  </Text>

}
