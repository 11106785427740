import React, { useMemo } from 'react'
import { AppForms, variantProvider } from '@/app'
import { useForm, useI18N, useState } from '@codeleap/common'
import { Button, Select, Text, TextInput, View, Calendar, dateformat } from '@/components'
import { APIClient } from '@/services'
import { useAppSelector } from '@/redux'
import { useKeydown, useOnboarding } from '@/utils'
import { navigate } from 'gatsby'
import { format, subYears } from 'date-fns'
import { analytics } from '@/services'
type AboutYouFormProps = {
  isModal?: boolean
}

export const AboutYouForm = ({ isModal = true }: AboutYouFormProps) => {
  const form = useForm(AppForms.aboutYou, {})
  const isFocused = useAppSelector(state => state?.AppStatus?.modals?.aboutYou)

  const { t } = useI18N()
  const { editProfile, options } = APIClient.Session.useEdit()
  const onboarding = useOnboarding()

  const [dateValue, _setDateValue] = useState<Date>(null)

  const defaultDate = useMemo(() => {

    const date = new Date()

    return subYears(date, 30)

  }, [])

  const setDateValue = (date: Date) => {
    _setDateValue(date)
  }
  async function onSubmit() {
    await editProfile({
      ...form.values,
      birthday: dateValue,
    })

    analytics.track('about_you', {
      country: form.values.country,
      advertising_source: form.values.inbound_source,
      climate_priority: form.values.climate_priority,
      date_of_birth: dateValue,
    })

    analytics.track('personal_details')

    if (isModal) {
      onboarding.next()
    } else {
      navigate('/auth/aboutJob/')
    }
  }

  useKeydown(() => {
    const isInvalidSubmit = isModal && !isFocused || !form.isValid
    if (isInvalidSubmit) return
    onSubmit?.()
  }, [isFocused, isModal, form?.values, dateValue], 'enter')

  const selectedInboundSource = options?.inbound_source.find((item) => item.value === form.values.inbound_source)

  return (
    <View variants={['flex', 'column']} >
      <Text
        variants={['h1', 'extraBold', 'textCenter', 'marginBottom:4']}
        text={t('AboutYou.title')}
      />
      <Text
        variants={['p1', 'marginBottom:4', 'marginTop:-3', 'textCenter']}
        text={t('AboutYou.sub')}
      />

      <View variants={['column', 'gap:3', 'center']}>
        <View variants={['column', 'gap:1', 'fullWidth']}>
          <Calendar
            label={t('AboutYou.calendarLabel')}
            placeholder={format(defaultDate, dateformat)}
            value={dateValue}
            onValueChange={setDateValue}
            leftIcon={{ name: 'calendar' }}
            rightIcon={{ name: 'chevron-down' }}
            variants={['fullWidth']}
            style={styles.calendarWrapper}
            defaultValue={defaultDate}

          />
          <Select
            {...form.register('gender')}
            options={options?.gender}
            variants={['fullWidth']}
          />
          <Select
            {...form.register('country')}
            options={options?.country}
            variants={['fullWidth']}
            searchable
          />
          <Select
            {...form.register('inbound_source')}
            options={options?.inbound_source}
            variants={['fullWidth']}
          />

          {selectedInboundSource?.value === 'other' ? (
            <TextInput {...form.register('inbound_source_details')} />
          ) : null}

          <Select
            {...form.register('climate_priority')}
            options={options?.climate_priority}
            variants={['fullWidth']}
          />
        </View>

        <View variants={['row', 'justifySpaceBetween']} style={styles.footer}>
          <Button
            text={t('AboutYou.continue')}
            disabled={!form.isValid}
            debugName={'Continue Button'}
            variants={['pill', 'large', 'neutral9', 'fullWidth', !form.isValid && 'outline']}
            onPress={onSubmit}
          />
        </View>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  calendarWrapper: {
    zIndex: '1',
  },
  footer: {
    width: '50%',

    [theme.media.down('small')]: {
      ...theme.presets.fullWidth,
    },
  },
}), true)
